import Cookies from 'js-cookie';
import { notification } from 'antd';
import { client } from '../../services/api';
import { logOut } from '../../redux/authentication/actionCreator';

class DataService {
  static get(path = '', params = {}) {
    return client({
      method: 'GET',
      params,
      url: path,
      headers: { "Authorization": `Bearer ${Cookies.get("access.token")}` }
    });
  }

  static post(path = '', data = {}, formData = "application/json") {
    return client({
      method: 'POST',
      url: path,
      data,
      headers: { "Authorization": `Bearer ${Cookies.get("access.token")}`, "Content-Type": formData },
    });
  }

  static patch(path = '', data = {}) {
    return client({
      method: 'PATCH',
      url: path,
      data: JSON.stringify(data),
      headers: { "Authorization": `Bearer ${Cookies.get("access.token")}` },
    });
  }

  static delete(path = '', data = {}) {
    return client({
      method: 'DELETE',
      url: path,
      data: JSON.stringify(data),
      headers: { "Authorization": `Bearer ${Cookies.get("access.token")}` },
    });
  }

  static put(path = '', data = {}) {
    return client({
      method: 'PUT',
      url: path,
      data: JSON.stringify(data),
      headers: { "Authorization": `Bearer ${Cookies.get("access.token")}` },
    });
  }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use((config) => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const requestConfig = config;
  const { headers } = config;
  requestConfig.headers = {
    ...headers,
  };

  return requestConfig;
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    if (response) {
      if (response.status === 401) {
        logOut();
        window.location.href = '/login';
        
        notification.info({
          message: 'Token expirado, refaça seu login.',
        });
      }
      return Promise.reject(error);
    }
  },
);
export { DataService };
