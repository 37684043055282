import React, { lazy } from 'react';
import { Route, useRouteMatch, Switch } from 'react-router-dom';

const Product = lazy(() => import('../../container/ecommerce/product/Products'));
const ProductAdd = lazy(() => import('../../container/ecommerce/product/AddProduct'));
const Invoice = lazy(() => import('../../container/ecommerce/Invoice'));
const Orders = lazy(() => import('../../container/ecommerce/Orders'));

function EcommerceRoute() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/products`} component={Product} />
      <Route exact path={`${path}/add-product`} component={ProductAdd} />
      <Route exact path={`${path}/edit-product/:id`} component={ProductAdd} />
      <Route exact path={`${path}/invoice`} component={Invoice} />
      <Route exact path={`${path}/orders`} component={Orders} />
    </Switch>
  );
}

export default EcommerceRoute;
