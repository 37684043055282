/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useState, useCallback, useEffect } from 'react';
import { notification } from 'antd';
import { handleRecursiveFormatCategories } from './Marketplace/RecursivesCategories';
import { DataService } from "../../config/dataService/dataService";

export const SettingsCategoriesContext = createContext();

export const SettingsCategoriesProvider = ({ children }) => {
    const [idMkt, setIdMkt] = useState();
    const [marketplacesTreeData, setMarketplacesTreeData] = useState([]);
    const [api, contextHolder] = notification.useNotification();
    const [expandedKeysSeller, setExpandedKeysSeller] = useState([]);
    const [marketplacesList, setMarketplacesList] = useState([]);
    const [foundedCategories, setFoundedCategories] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [loadingCategoriesMarketplaces, setLoadingCategoriesMarketplaces] = useState(false);
    const [searchValueMkt, setSearchValueMkt] = useState('');
    const [current, setCurrent] = useState(0);
    const [dataCategories, setDataCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [categorySelected, setCategorySelected] = useState();
    const [filteredTreeData, setFilteredTreeData] = useState();
    const [categorySelectedMarketplace, setCategorySelectedMarketplace] = useState();
    const [categoriesForLink, setCategoriesForLink] = useState([]);
    const [checkCategories, setCheckCategories] = useState([]);
    const [listAttributtes, setListAttributes] = useState([]);
    const [listAttributtesMarketplace, setListAttributesMarketplace] = useState([]);
    const [expandedKeysAttriutesMkt, setExpandedKeysAttriutesMkt] = useState([]);
    const [linkCategories, setLinkCategories] = useState([]);
    const [linkAttributes, setLinkAttributes] = useState([]);
    const [attributeSelected, setAttributeSelected] = useState();
    const [attributeSelectedMarketplace, setAttributeSelectedMarketplace] = useState();
    const [filteredTreeDataMkt, setFilteredTreeDataMkt] = useState();

    const steps = [
        { title: "Categorias", description: "Selecione a categoria para vinculação." },
        { title: "Categorias Marketplaces", description: "Víncule a categoria ás categorias dos marketplaces." },
        { title: "Atributos", description: "Preencha os campos de atributos." }
    ];

    const openNotificationWithIcon = useCallback((type, title, desc) => {
        api[type]({
            message: title,
            description: desc,
        });
    }, [api]);

    const fetchMarketplaceTreeData = async (id) => {
        setLoadingCategoriesMarketplaces(true)
        try {
            const response = await DataService.get(`/category/marketplace/${id}`)

            if (response.status && response.status === 200) {
                const returnTree = await handleRecursiveFormatCategories(response.data, id);

                setMarketplacesTreeData(prev => ({
                    ...prev,
                    [id]: returnTree
                }))
            }
            setLoadingCategoriesMarketplaces(false)
        } catch (error) {
            openNotificationWithIcon('error', 'Erro!', `Erro ao buscar as categorias do marketplace ${id}`);
            setLoadingCategoriesMarketplaces(false)
        }
    }

    const onChangeTabs = (idTab) => {
        setIdMkt(idTab)
        if (Number(idTab) !== 0 && !marketplacesTreeData[Number(idTab)]) {
            fetchMarketplaceTreeData(Number(idTab))
        }
    }

    const findCategoryById = (categories, id) => {
        return categories.reduce((found, category) => {
            if (found) return found;
            if (Number(category.id) === Number(id)) return category;
            if (category.children && category.children.length > 0) return findCategoryById(category.children, id);

            return null;
        }, null);
    };

    const filterTreeData = (value, isMkt) => {
        const filter = (nodes) => {
            return nodes
                .map(node => {
                    const children = filter(node.children || []);
                    if (node.title.toLowerCase().includes(value.toLowerCase()) || children.length) {
                        return { ...node, children };
                    }
                    return null;
                })
                .filter(node => node);
        };

        if (!isMkt && current === 1) setFilteredTreeData(filter(foundedCategories || []))
        else setFilteredTreeDataMkt(filter(marketplacesTreeData[idMkt] || []))
    };

    const processTreeData = (data) => {
        return data.map(item => ({
            title: item.name, // Define o título do nó
            key: String(item.id), // Garante que a chave seja uma string
            value: String(item.id), // Define o valor como o ID do nó
            selectable: item.selectable !== undefined ? item.selectable : true, // Garante que selectable seja booleano
            children: item.children ? processTreeData(item.children) : [] // Processa os filhos recursivamente
        }));
    };

    const foundAttributesSeller = async () => {
        const response = await DataService.get(`/category/${categorySelected.id}/attributes`);

        if (response.status && response.status === 200) {
            const attributes = processTreeData(response.data);
            setListAttributes(attributes);
        }
    };

    const foundAttributesMarketplace = async (idCategory) => {
        const response = await DataService.get(`/category/marketplace/${idMkt}/attributes/${idCategory}`);

        if (response.status && response.status === 200) {
            response.data.forEach(attribute => {
                attribute.value = attribute.id
                attribute.key = attribute.id
                attribute.title = attribute.name
            })
            setListAttributesMarketplace(response.data)
        }
    }

    const onSelectAttributeMarketplace = (e) => {
        const found = listAttributtesMarketplace.find(attribute => Number(attribute.id) === Number(e))
        if (found) setAttributeSelectedMarketplace(found)
    }

    const onSelectMarketplaceAttributes = (e) => {
        setExpandedKeysSeller((prevExpandedKeys) => [...prevExpandedKeys]);
        foundAttributesMarketplace(e)
    }

    useEffect(() => {
        if (current > 1 && categorySelected) foundAttributesSeller();
    }, [categorySelected])

    const onSelectSeller = (e, isAttribute) => {
        setExpandedKeysSeller((prevExpandedKeys) => [...prevExpandedKeys]);
        const categoryFounded = findCategoryById(foundedCategories, e);
        setCategorySelected(categoryFounded);
    };

    const onSelectAttributeSeller = (e) => {
        const found = listAttributtes.find(attribute => Number(attribute.value) === Number(e))
        if (found) setAttributeSelected(found)
    };

    const onChangeMktCategoriasAttributes = async (e) => {
        fetchMarketplaceTreeData(Number(e));
    }

    const fetchCategories = async () => {
        try {
            const response = await DataService.get("/category");
            const updateDisabledField = (categories) => categories.map(category => ({
                ...category,
                disabled: false,
                selectable: !category.children,
                children: category.children ? updateDisabledField(category.children) : []
            }));

            const categories = updateDisabledField(response.data.Data);
            setDataCategories(categories);
        } catch (error) {
            console.error(`Error: ${error}`);
            openNotificationWithIcon("error", "Erro", "Não foi possível carregar as categorias.");
        }
    };

    useEffect(() => {
        const requestCategories = async () => {
            setLoading(true);
            try {
                await fetchCategories();
            } finally {
                setLoading(false);
            }
        };
        requestCategories();
    }, [openNotificationWithIcon]);

    return (
        <SettingsCategoriesContext.Provider
            value={{
                steps,
                setCurrent,
                onSelectSeller,
                dataCategories,
                categoriesForLink,
                setCategoriesForLink,
                setDataCategories,
                setSearchValueMkt,
                searchValueMkt,
                findCategoryById,
                filteredTreeDataMkt,
                setFilteredTreeDataMkt,
                filterTreeData,
                current,
                setCheckCategories,
                listAttributtesMarketplace,
                searchValue,
                onSelectAttributeSeller,
                setSearchValue,
                setLinkCategories,
                linkCategories,
                onChangeMktCategoriasAttributes,
                onChangeTabs,
                foundedCategories,
                listAttributtes,
                fetchMarketplaceTreeData,
                expandedKeysSeller,
                attributeSelectedMarketplace,
                attributeSelected,
                setAttributeSelected,
                onSelectMarketplaceAttributes,
                onSelectAttributeMarketplace,
                setAttributeSelectedMarketplace,
                expandedKeysAttriutesMkt,
                setExpandedKeysAttriutesMkt,
                filteredTreeData,
                setFilteredTreeData,
                setExpandedKeysSeller,
                loadingCategoriesMarketplaces,
                setLoadingCategoriesMarketplaces,
                setFoundedCategories,
                setIdMkt,
                idMkt,
                linkAttributes,
                setLinkAttributes,
                checkCategories,
                setMarketplacesTreeData,
                marketplacesTreeData,
                loading,
                setCategorySelectedMarketplace,
                categorySelectedMarketplace,
                marketplacesList,
                setMarketplacesList,
                categorySelected,
                setCategorySelected,
                openNotificationWithIcon,
                contextHolder,
            }}
        >
            {children}
        </SettingsCategoriesContext.Provider>
    );
};
