/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { DataService } from '../../../../config/dataService/dataService';

// Criação do contexto
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(() => {
        // Tenta carregar os dados do localStorage quando o componente for montado
        const savedUser = localStorage.getItem('user');
        return savedUser ? JSON.parse(savedUser) : null;
    });

    useEffect(() => {
        if (user) {
            localStorage.setItem('user', JSON.stringify(user)); // Salva no localStorage
        }
    }, [user])

    const loginUser = (userData) => {
        const x = jwtDecode(userData.token);
        const getUser = async () => {
            const response = await DataService.get(`/user/${x.userId}`)
            setUser(response.data);
        }
        getUser();
    };

    const logoutUser = () => {
        setUser(null);
        localStorage.removeItem('user'); // Remove do localStorage ao deslogar
    };

    return (
        <AuthContext.Provider value={{ user, loginUser, logoutUser }}>
            {children}
        </AuthContext.Provider>
    );
};
