import { DataService } from "../config/dataService/dataService";

export async function getMarketplaces() {
  try {
      const response = await DataService.get('/integrator');
      return response.data.data;
  } catch (error) {
      console.error('Error fetching marketplaces:', error);
      return [];
  }
}


export async function getCredentials() {
    return DataService
      .get(`/credential`)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return false
      });
}

export async function saveCredentials(data){
    return DataService
      .put('/credential', data)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return false
      });
}

export async function getNotifications() {
  try {
    const response = await DataService.get('/notifications');
    return response.data;
  } catch (error) {
    console.error('Error fetching notifications:', error);
    return [];
  }
}

export async function putNotification(notificationId){
    return DataService
      .put(`/notification/${notificationId}`)
      .then((response) => {
        return response;
      })
      .catch(() => {
        return false
      });
}