import axios from "axios";

export const client = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_URL,
});

export const server = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_URL,
  headers:{
    "Content-Type": "application/json",
  }
});
