import { server } from "../services/api";

export async function auth(username, password) {
    return server.post("/login", { email: username, password }).then(response => {
      return response.data;
    }).catch(() => {
      return false
    });
}

export async function logout(token){
    return server.post("/logout", { token }).then(response => {
      return response.data;
    }).catch(() => {
      return false
    });
}