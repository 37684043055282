import React from 'react';
import { Row, Col, Divider } from 'antd';
import { Aside, Content, Wrapper } from './overview/style';
// import Heading from '../../../components/heading/heading';

const AuthLayout = (WraperContent) => {
  return function () {
    return (
      <Wrapper>
        <img src='/img/bg_illustration.png' alt="bg-illustration" className='bg-illustration' />
        <Row style={{ minHeight: '100%', minWidth: '100%' }} className='content-wrapper'>
          <Col xxl={14} xl={14} lg={14} md={8} xs={24} className='illustration-wrapper'>
            <Aside>
              <Content>
                <img
                  src='/img/SVG_Illustration.svg'
                  alt=""
                />
              </Content>
            </Aside>                
          </Col>

          <Col xxl={10} xl={10} lg={10} md={16} xs={24} className='form-wrapper'>
            <WraperContent />
          </Col>
        </Row>
      </Wrapper>
    );
  };
};

export default AuthLayout;
