import React from "react";
import { Menu } from "antd";
import { NavLink, useRouteMatch } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { SettingOutlined } from "@ant-design/icons";
import propTypes from "prop-types";
import { IconStyled } from "./style"; 

const { SubMenu } = Menu;

function MenuItems({ darkMode, toggleCollapsed, topMenu }) {
  const { path } = useRouteMatch();
  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      <Menu.Item key="Dashboard" icon={<FeatherIcon icon="home"/>} >
        <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
          Dashboard
        </NavLink>
      </Menu.Item>
      <Menu.Item key="products" icon={<FeatherIcon icon="shopping-bag"/>} >
        <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/products`}>
          Produtos
        </NavLink>
      </Menu.Item>
      <Menu.Item key="orders" icon={<FeatherIcon icon="tag"/>} >
        <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/orders`}>
          Pedidos
        </NavLink>
      </Menu.Item>
      <Menu.Item 
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/publish/publish-list`}>
              <FeatherIcon icon="send" />
            </NavLink>
          )
        }
        key="publish"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/publish/publish-list`}>
          Publicações
        </NavLink>
      </Menu.Item>
      {/* <Menu.Item key="Invoice" icon={<FeatherIcon icon="dollar-sign"/>} >   
        <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/Invoice`}>
          Faturas
        </NavLink>
      </Menu.Item> */}
      <Menu.Item 
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/marketplaces`}>
              <FeatherIcon icon="truck" />
            </NavLink>
          )
        }
        key="marketplaces"
      >
        <NavLink to={`${path}/marketplaces`}>
          Marketplaces
        </NavLink>
      </Menu.Item>
      <Menu.Item 
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/company`}>
              <FeatherIcon icon="bookmark" />
            </NavLink>
          )
        }
        key="company"
      >
        <NavLink to={`${path}/company`}>
          Dados da Empresa
        </NavLink>
      </Menu.Item>
      <Menu.Item 
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/users/users-list`}>
              <FeatherIcon icon="bookmark" />
            </NavLink>
          )
        }
        key="users"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/users/users-list`}>
          Usuários
        </NavLink>
      </Menu.Item>
      <IconStyled>
        <SubMenu key="vinculo" icon={<SettingOutlined/>} title="Configuração">
          <Menu.Item key="categories">
            <NavLink to={`${path}/settings/categories`}>
              Categorias
            </NavLink>
          </Menu.Item>
        </SubMenu>
      </IconStyled>
      
    </Menu>
  );
}

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
