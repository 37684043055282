import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const List = lazy(() => import('../../container/publish/Publish'));

function PagesRoute() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/publish-list`} component={List} />
    </Switch>
  );
}

export default PagesRoute;
