
import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { SettingsCategoriesProvider, SettingsCategoriesContext } from '../../container/settings/SettingsCategoriesContext';

const SettingsCategories = lazy(() => import("../../container/settings/SettingsCategories"))

function SettingsRoute() {
  const { path } = useRouteMatch();


  return (
    <Switch>
      <SettingsCategoriesProvider>
        <Route path={`${path}/categories`} render={() => <SettingsCategories/>} />
      </SettingsCategoriesProvider>
    </Switch>
  );
}

export default SettingsRoute;
