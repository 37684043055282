export const handleRecursiveFormatCategories = (treeData, id) => {

    const recursiveAmazon = () => {
        return "";
    }

    const recursiveMagalu = () => {
        return "";
    }

    const recursiveCnova = (tree) => {
        if (tree && tree.length) {
            tree.forEach(category => {
                const child = Array.isArray(category.categories) ? category.categories : category.Categories;
                category.title = category.name;
                category.key = String(category.id);
                category.value = String(category.id)
                category.parent = Number(category.parentId)
                category.selectable = !child || !child.length || child.length === 0
                category.children = child;
                if (category.hasChildren) {
                    const children = child;
                    if (children) {
                        recursiveCnova(children);
                    }
                }
            });
        }

        return tree;
    }


    const recursiveMeli = () => {
        return "";
    }

    switch (id) {
        case 2: // Amazon
            return recursiveAmazon(treeData);
        case 5: // Magalu 
            return recursiveMagalu(treeData);
        case 6: // Mercado Livre
            return recursiveMeli(treeData);
        case 7: // CNOVA
            return recursiveCnova(treeData);

        default:
    }
}