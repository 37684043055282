import Cookies from 'js-cookie';
import actions from './actions';
import { auth } from '../../functions/users';

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr } = actions;

const login = (username, password) => {
  return async (dispatch) => {
    try {
      dispatch(loginBegin());
      const res = await auth(username, password);
      
      if (res.token) {
        const expires = new Date();
        expires.setMinutes(expires.getMinutes() + 60);
        Cookies.set('access.token', res.token, { expires });
        Cookies.set('logedIn', true);
        
        dispatch(loginSuccess(true));
        return res;  // Retorna os dados do usuário
      } 
        const errorMessage = 'Não foi possível autenticar. Revise seu e-mail e senha';
        dispatch(loginErr(errorMessage));
        throw new Error(errorMessage);
      
      
    } catch (err) {
      dispatch(loginErr(err));
      throw err;  // Lança o erro para que possa ser tratado no componente
    }
  };
};

const logOut = () => {
  return async (dispatch) => {
    try {
      dispatch(logoutBegin());
      Cookies.remove('logedIn');
      Cookies.remove('access.token');
      return dispatch(logoutSuccess());
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { login, logOut };
