import React, { useState, useEffect, useCallback } from 'react';
import { Badge, notification as AntdNotification } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { useSelector } from 'react-redux';
import { AtbdTopDropdwon } from './auth-info-style';
import { Popover } from '../../popup/popup';
import Heading from '../../heading/heading';
import { getNotifications, putNotification } from '../../../functions/marketplaces';

const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    backgroundColor: '#F1F2F6',
  };
  return <div style={{ ...style, ...thumbStyle }} {...props} />;
};

const renderTrackVertical = (rtl) => {
  const thumbStyle = {
    position: 'absolute',
    width: '6px',
    transition: 'opacity 200ms ease 0s',
    opacity: 0,
    [rtl ? 'left' : 'right']: '2px',
    bottom: '2px',
    top: '2px',
    borderRadius: '3px',
  };
  return <div style={thumbStyle} />;
};

const renderView = ({ style, rtl, ...props }) => {
  const customStyle = {
    marginRight: rtl && 'auto',
    [rtl ? 'marginLeft' : 'marginRight']: '-17px',
  };
  return <div {...props} style={{ ...style, ...customStyle }} />;
};

renderThumb.propTypes = {
  style: PropTypes.shape(PropTypes.object),
};

renderView.propTypes = {
  style: PropTypes.shape(PropTypes.object),
  rtl: PropTypes.bool,
};

function NotificationBox() {
  const [notifications, setNotifications] = useState([]);
  const [api, contextHolder] = AntdNotification.useNotification();

  const { rtl } = useSelector(state => ({
    rtl: state.ChangeLayoutMode.rtlData,
  }));

  const handleNotificationClick = useCallback(async (e, id, link) => {
    e.stopPropagation();
    try {
      const response = await putNotification(id);
      if (response.status === 200) {
        window.location.href = link;
      } else {
        api.error({
          message: 'Erro',
          description: 'Não foi possível marcar a notificação como lida.',
        });
      }
    } catch (err) {
      api.error({
        message: 'Erro',
        description: 'Não foi possível redirecionar.',
      });
    }
  }, [api]);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const fetchedNotifications = await getNotifications();
        setNotifications(fetchedNotifications || []);
      } catch (error) {
        setNotifications([]);
      }
    };
    fetchNotifications();
  }, []);

  const content = (
      <AtbdTopDropdwon className="atbd-top-dropdwon sticky-notification">
        <Heading as="h5" className="atbd-top-dropdwon__title">
          <span className="title-text">Notifications</span>
          <Badge className="badge-success" count={notifications.length} />
        </Heading>
        <Scrollbars
          autoHeight
          autoHide
          renderThumbVertical={(props) => renderThumb(props)}
          renderView={(props) => renderView({ ...props, rtl })}
          renderTrackVertical={() => renderTrackVertical(rtl)}
        >
          <ul className="atbd-top-dropdwon__nav notification-list">
            {notifications.length > 0 ? (
              notifications.map((item, index) => (
                <li key={`notification-${index}`}>
                  <Link to="#" onClick={(e) => handleNotificationClick(e, item.id, item.link_destiny)}>
                    <div className="atbd-top-dropdwon__content notifications">
                      <div className="notification-icon bg-primary">
                        <FeatherIcon icon="hard-drive" />
                      </div>
                      <div className="notification-content d-flex">
                        <div className="notification-text">
                          <Heading as="h5">{item.mensage}</Heading>
                          <p>{new Date(item.date_send).toLocaleDateString()}</p>
                        </div>
                        <div className="notification-status">
                          <Badge dot />
                        </div>
                      </div>
                    </div>
                  </Link>
                </li>
              ))
            ) : (
              <li>
                <div className="atbd-top-dropdwon__content notifications">
                  <div className="notification-content d-flex">
                    <div className="notification-text">
                      <Heading as="h5">Nenhuma notificação.</Heading>
                    </div>
                  </div>
                </div>
              </li>
            )}
          </ul>
        </Scrollbars>
      </AtbdTopDropdwon>
  );

  return (
    <div className="notification">
      {contextHolder}
      <Popover placement="bottomLeft" content={content} action="click">
        <Badge dot={notifications.length > 0} offset={[-8, -5]}>
          <Link to="#" className="head-example">
            <FeatherIcon icon="bell" size={20} />
          </Link>
        </Badge>
      </Popover>
    </div>
  );
}

export default NotificationBox;
